import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Daimler AG<br />
          technology ➝ React, AEM<br />
          location ➝ Berlin (Germany)
          </p>
        <p>OneWeb Vans is a platform with a “premium custom experience” featuring advanced van configurators with a strong B2B focus. Platofrm is serving 25 markets.<br />
        My role involved the implementation of a new front-end based on an updated UI guide from Mercedes AG. I helped internal and external design teams prepare workflows and align frontend (React & Web Components) and backend (AEM) teams.</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/project_quote_container_3_OneWebVans.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-a.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-b.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-c.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/one-web-vans/OneWebVans-d.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const MBVANS = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• OneWeb Vans •</h1>
      <Gallery />
    </div>
  )
}