import { NavLink, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/1.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/2.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/3.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/4.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/5.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/6.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/7.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/8.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/9.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/private/authonomy/10.jpg" alt="" /></div>
      </div>
    </div>
  )
}

const Description = () => {
  return(
    <div className="article-holder">
      <p>In Authonomy I explore one of the basic behaviors of life — continual change. In this example, the entity is represented as a derivative of a double pendulum (also known as a chaotic pendulum). The entity’s movement is a result of a rotation impulse applied at both joints with a random period. The environment also has a function of change represented as a variable gravity.</p>
      <p>The work is made with three.js and Rapier — a fast game/physics engine made with Rust programming language. All textures, normal and light maps are created dynamically using Canvas. This work is best viewed at 4k & 120Hz or better display.</p>
      <p>Online version is available on my <a href="https://vedrankolac.github.io/authonomy/">GitHub</a></p>
      <p><i>Software</i><br />
        Javascript, threejs and Rapier
      </p>
    </div>
    
  )
}

export const AUTHONOMY = () => {
  const { id } = useParams();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• AUTHONOMY •</h1>
      <nav>
        <ul>
          <li><NavLink to="/private/authonomy/gallery">Gallery</NavLink><span>,&nbsp;</span></li>
          <li><NavLink to="/private/authonomy/description">Description</NavLink></li>
        </ul>
      </nav>
      {(id === 'gallery' ? <Gallery /> : <Description />)}
    </div>
  )
}