import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ CDLX | Good Year (EU)<br />
          technology ➝ React, WebComponents, AEM<br />
          location ➝ Berlin (Germany), Brusells (Belgium)<br /></p>
        <p>I have managed a team of frontend engineers at the Berlin office in coordination with the management and backend team in Belgium. We focused on developing React and Web Components used in AEM to create a sophisticated e-commerce platform.</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/goodyear/goodyear-1.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/goodyear/goodyear-2.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/goodyear/goodyear-3a.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/goodyear/goodyear-4.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/goodyear/goodyear-5.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const GOODYEAR = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• Good Year (EU) •</h1>
      <Gallery />
    </div>
  )
}