import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Mercedes-Benz AG<br />
          technology ➝ React, Next.js, three.js<br />
          location ➝ Berlin (Germany)</p>
        <p>One smart Web is an ecosystem of various applications, functions and services that is used in 25 markets.<br />
          My role involved refactoring for better performance and general visual quality. There was a main issue regarding slow first paint and I was working on optimisation.
        </p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-smart-web/project_stage_one-smart-web.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/one-smart-web/project_story_2_one-smart-web.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/one-smart-web/project_story_1_one-smart-web.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const MBSMART = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• One smart Web •</h1>
      <Gallery />
    </div>
  )
}