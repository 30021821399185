import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Experience One | Mercedes-Benz AG<br />
          technology ➝ Angular<br />
          location ➝ Berlin (Germany)</p>
        <p>EO Touch offers customers a starting point for the showroom experience. The focus is on ease of use and the special experience factor: from the large-scale power wall to the interactive 3D vehicle configurator.<br />
        My focus was on building new functionalities and refactoring for better performance. I have buit new animation system and enhanced communication between terminal and large-scale wall.
        </p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/eo-digital-showroom-stage.jpg" alt="" /></div>
      </div>
      <div className='pair-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/project_story_1_showroom.jpg" alt="" /></div>
        <div className='img-holder'><Photo src="../../assets/commercial/digital-showroom/project_story_2_showroom.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const DIGITALSHOWROOM = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• Digital Showroom •</h1>
      <Gallery />
    </div>
  )
}