import { useEffect } from 'react'
import { Photo } from '../Photo';

const Gallery = () => {
  return(
    <div className='gallery-holder'>
      <div className='inline-description'>
        <p className='italic'>client ➝ Peace Collective<br />
          technology ➝ Javascript, Liquid<br />
          location ➝ Toronto (Canada)<br /></p>
        <p>My role involved new Shopify theme development in close collaboration with the design team. I did a complete rewrite of the shop and increased load time by more than 70%. I have also developed a new system for advanced homepage structure configuration and various sales-specific tools.</p>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_01.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_02.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_03.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_04.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_05.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_06.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_07.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_08.jpg" alt="" /></div>
      </div>
      <div className='fullwidth-gallery'>
        <div className='img-holder'><Photo src="../../assets/commercial/peace-collective/pc_09.jpg" alt="" /></div>
      </div>
    </div>
  )
}

export const PEACECOLLECTIVE = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="project-detail-page">
      <h1>• Peace Collective •</h1>
      <Gallery />
    </div>
  )
}